import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { AbstractApiWrapperService, AbstractUserDataService } from '@shared/auth';

@NgModule({})
export class AuthDataAccessModule {
    static forRoot(config: {
        apiWrapperClass: Type<AbstractApiWrapperService<any, any>>;
        userDataServiceClass: Type<AbstractUserDataService<any>>;
    }): ModuleWithProviders<AuthDataAccessModule> {
        return {
            ngModule: AuthDataAccessModule,
            providers: [
                {
                    provide: AbstractApiWrapperService,
                    useExisting: config.apiWrapperClass,
                },
                {
                    provide: AbstractUserDataService,
                    useExisting: config.userDataServiceClass,
                },
            ],
        };
    }
}
